<template>
	<div class="details">
		<headers></headers>
		<el-carousel class="bannerimgs" indicator-position="outside" height="600px">
			<el-carousel-item v-for="item in imglist" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<el-carousel class="phonebannerimgs" indicator-position="outside" height="200px">
			<el-carousel-item v-for="item in imglist2" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<div class="navigation">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item v-if="$route.query.type.split('-')[0] == 3">校企合作</el-breadcrumb-item>
				<el-breadcrumb-item v-if="$route.query.type.split('-')[0] == 4">行业服务</el-breadcrumb-item>
				<el-breadcrumb-item v-if="$route.query.type == '3-1'">专业（群）建设辅助</el-breadcrumb-item>
				<el-breadcrumb-item v-else-if="$route.query.type == '4-1'">幼儿园加盟</el-breadcrumb-item>
				<el-breadcrumb-item v-else>{{$route.query.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<template v-if="contdata.type == 0">
			<div class="zt">
				<div class="title">{{$route.query.name}}</div>
				<div class="cont conts" v-html="contdata.content">

				</div>
			</div>
		</template>
		<template v-else-if="contdata.type == 1">
			<div class="zt">
				<div class="title">{{$route.query.name}}</div>
				<div class="fl" v-for="(item,index) in contdata.content">
					<div class="tit">{{item.tits}}</div>
					<p v-html="item.cont">

					</p>
					<template v-if="item.childer&&item.childer.length>0">
						<div class="fl-fl" v-for="(e,r) in item.childer">
							<div>{{e.tits}}</div>
							<p v-html="e.cont">

							</p>
						</div>
					</template>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="zt">
				<div class="title">{{$route.query.name}}</div>
				<div class="cont conts">
					暂无内容
				</div>
			</div>
		</template>
		<formdata></formdata>
		<footers></footers>
	</div>
</template>

<script>
	import headers from '../components/header.vue'
	import footers from '../components/footer.vue'
	import formdata from '../components/forsubmit.vue'
	export default {
		components: {
			headers,
			footers,
			formdata
		},
		data() {
			return {
				contdata: "",
				imglist: [
					require('../assets/img/c8644a2.png')
				],
				imglist2: [
					require('../assets/img/90b38e98fa91855976d30ef2b935fdf.png')
				],
			}
		},
		watch: {
			'$route.query.type'(val) {
				// console.log(val)
				// console.log(this.$commonFun.getBeforStr(val, '-'))
				// console.log(this.$commonFun.getAfterStr(val, '-'))
				// this.is_show = false
				this.getcontdata(this.$commonFun.getBeforStr(val, '-'), this.$commonFun.getAfterStr(val, '-'))
				this.querySortImg(this.$commonFun.getBeforStr(val, '-'), this.$commonFun.getAfterStr(val, '-'))
			},
		},
		created() {},
		mounted() {
			// console.log(this.$commonFun.getBeforStr(this.$route.query.type, '-'))
			// console.log(this.$commonFun.getAfterStr(this.$route.query.type, '-'))
			this.getcontdata(this.$commonFun.getBeforStr(this.$route.query.type, '-'), this.$commonFun.getAfterStr(this
				.$route.query.type, '-'))
			this.querySortImg(this.$commonFun.getBeforStr(this.$route.query.type, '-'), this.$commonFun.getAfterStr(this
				.$route.query.type, '-'))
		},
		methods: {
			getcontdata(hostRank, secondRank) {
				this.$post('getWebContent', {
					hostRank: hostRank,
					secondRank: secondRank,
					sign: 0
				}).then((res) => {
					// console.log(res)
					if (res && res.length > 0) {
						if (res[0].type == 1) {
							res[0].content = JSON.parse(res[0].content)
							let ghh = JSON.parse(res[0].content)
							res[0].content = ghh
							console.log(res[0])
							// console.log(str2)
						}
						// console.log(res[0])
						this.contdata = res[0]
					} else {
						this.contdata = ''
					}
				})
			},
			querySortImg(sortHead, sortOrder) {
				let gh = []
				this.$post('querySortImg', {
					sortHead: sortHead,
					sortOrder: sortOrder,
					sortFrom: 0
				}).then((res) => {
					if (res&& res.length > 0) {
						this.imglist = []
						this.imglist2 = []
						res.forEach((e, m) => {
							let h = e.sortImg.split(',')
							h.forEach((v, b) => {
								if (e.sortSign == 0) {
									this.imglist.push(v)
								} else {
									gh.push(v)
								}
							})
						})
						if (!gh || gh.length <= 0) {
							this.imglist.forEach((f, g) => {
								f = f + '?x-bce-process=image/crop,x_500,y_200,w_701,h_400'
								this.imglist2.push(f)
							})
						}else{
							this.imglist2 = gh
						}
					} else {

					}
				})
				this.$nextTick(() => {

				})
			}
		}
	}
</script>

<style scoped lang="less">
	.details {
		.phonebannerimgs {
			display: none;
		}

		.navigation {
			width: 1200px;
			margin: 20px auto 40px auto;
		}

		.zt {
			width: 1200px;
			margin: 40px auto;

			.title {
				margin-bottom: 40px;
				position: relative;
				font-size: 22px;
				color: #333;
			}

			.title::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -17px;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: #f49952;
			}

			.cont {
				>p {
					line-height: 30px;
					font-size: 16px;
					text-indent: 2px;
				}

				>span {
					display: block;
					width: 40%;
					margin: 20px auto;
					border-radius: 10px;
					font-size: 0;
					height: 400px;
					max-height: 400px;
					overflow: hidden;

					>img {
						width: 100%;
					}
				}
			}

			.conts {
				>span {
					width: 90%;
					height: auto;
					max-height: none;
					overflow: hidden;

					>img.marg1 {
						margin: -122px 0 0 0;
					}

					>img.marg2 {
						margin: -104px 0 0 0;
					}
				}
			}
		}

		.fl {
			img{
				display: inline-block;
				vertical-align: top;
			}
			.tit {
				font-size: 20px;
				margin: 20px 0;
				color: #333;
			}

			>p {
				// text-indent: 35px;
				color: #666;
				font-size: 16px;
				line-height: 35px;
				
			}

			.fl-fl {
				// margin: 20px 0;
				font-size: 0;
				p{
					font-size: 16px;
				}
				>div {
					display: inline-block;
					font-size: 16px;
					color: #999;
					margin: 40px 0;
					position: relative;
					padding: 0 0 0 35px;
				}

				>div::after {
					content: '';
					display: block;
					position: absolute;
					bottom: -11px;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: #f49952;
				}

				>div::before {
					content: '';
					display: block;
					position: absolute;
					top: -6px;
					left: 9px;
					width: 4px;
					height: 40px;
					background-color: #f49952;
					transform: rotate(30deg);
				}

				>p {
					>p {
						max-height: 200px;
						overflow: hidden;

						>img {
							display: inline-block;
							vertical-align: middle;
							width: 24%;
							margin-right: 1.3%;
						}

						>:last-child {
							margin-right: 0;
						}
					}
				}

			}
		}
	}

	@media screen and (max-width:900px) {
		.details {
			.bannerimgs {
				display: none;
			}

			.phonebannerimgs {
				display: block;
				margin-top: 64px;
			}

			.navigation {
				width: 100%;
				padding: 0 0 0 15px;
				box-sizing: border-box;
			}

			.zt {
				padding: 0 10px;
				box-sizing: border-box;
				width: 100%;

				.title {
					font-size: 16px;
				}

				.cont {
					>span {
						width: 100%;
						height: auto;
						max-height: none;
					}
				}
			}

			.fl {
				.tit {
					font-size: 18px;
					margin: 20px 0;
				}

				>p {
					text-indent: 28px;
					font-size: 14px;
					line-height: 25px;
				}

				.fl-fl {
					>div {
						font-size: 12px;
						margin: 20px 0;
						padding: 0 0 0 20px;
					}

					>div::after {
						height: 1px;
						bottom: -7px;
					}

					>div::before {
						width: 1px;
						left: 5px;
						top: 4px;
						height: 20px;
					}

					>p {

						>img {}
					}

				}
			}
		}
	}
</style>
<style lang="less">
	.details {
		img{
			display: inline-block;
			vertical-align: top;
			margin-bottom: 15px;
		}
		.fl {
			.fl-fl {
				
				>p {
					>p {
						max-height: 200px;
						overflow: hidden;

						>img {
							display: inline-block;
							vertical-align: middle;
							width: 24%;
							margin-right: 1.3%;
						}

						>img:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
</style>
